@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* If you want to add your own default base styles for specific HTML elements,
 * use the @layer directive to add those styles to Tailwind’s base layer.
 *
 * https://tailwindcss.com/docs/adding-custom-styles#adding-base-styles
 */

@layer base {
  /* Document */
  html {
    @apply scroll-smooth font-body font-normal text-black antialiased;
  }

  /* Typography */
  h1 {
    @apply text-4xl font-bold sm:text-5xl;
  }

  h2 {
    @apply text-3xl font-bold;
  }

  h3 {
    @apply text-2xl font-bold;
  }

  h4 {
    @apply text-xl font-bold;
  }

  h5 {
    @apply text-lg font-bold;
  }

  h6 {
    @apply text-base font-bold;
  }

  /* HTML elements */
  blockquote {
    @apply relative text-lg sm:text-2xl;
  }

  code:before,
  code:after {
    content: "`";
  }

  pre {
    @apply overflow-x-scroll rounded-xl bg-black px-8 text-white;

    & code:before,
    & code:after {
      content: "";
    }
  }

  table {
    @apply w-full;

    & tr {
      @apply border-t;
    }

    & th,
    & td {
      @apply py-3 pr-3 text-left align-top;
    }
  }

  figure {
    @apply max-w-fit;
  }

  figcaption {
    @apply mt-3 italic opacity-60;
  }

  img ~ figcaption {
    @apply -mt-3;
  }

  figure img {
    @apply rounded-md;
  }

  .video-frame iframe {
    @apply h-full w-full;
  }

  /* Richtext */
  .richtext {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6,
    & p,
    & ul,
    & ol,
    & a,
    & blockquote,
    & code,
    & pre,
    & table,
    & figure,
    & iframe,
    & img,
    & hr {
      &:not(:last-child) {
        @apply mb-6;
      }
    }

    & p,
    & ul,
    & ol,
    & a,
    & blockquote,
    & code,
    & pre,
    & table,
    & figure,
    & iframe,
    & img,
    & hr {
      & + h1,
      & + h2,
      & + h3,
      & + h4,
      & + h5,
      & + h6 {
        @apply mt-12;
      }
    }

    & h2 a,
    & h3 a,
    & h4 a,
    & h5 a,
    & h6 a,
    & p a,
    & li a {
      @apply break-words underline;
    }

    & ul > li {
      &:before {
        content: "\2022";
        @apply absolute left-0 top-3 text-3xl leading-[0] text-orange;
      }
    }

    & ol {
      counter-reset: ol-count;
    }

    & ol > li {
      counter-increment: ol-count;

      &:before {
        content: counter(ol-count);
        @apply absolute left-0 top-3 text-base leading-[0] text-orange;
      }
    }

    & li {
      @apply relative list-none pl-6;
    }

    & .fancy-list li {
      @apply border-b border-black/25 pb-6;

      &:not(:first-child) {
        @apply mt-6;
      }
    }
  }

  /* Stop transitions before page has loaded */
  .preload {
    @apply opacity-0;

    & .transition {
      @apply !transition-none;
    }
  }

  /* Animate lazy load */
  .lazyload,
  .lazyloading {
    @apply opacity-0;
  }

  .lazyloading {
    @apply bg-gray-100 opacity-100 transition;
  }

  img.lazyload:not([src]) {
    visibility: hidden;
  }

  /* Checkboxes */
  .checkbox:hover input ~ .checkmark,
  .checkbox input:focus ~ .checkmark {
    @apply bg-gray-300;
  }

  .checkbox input:checked ~ .checkmark {
    @apply border-purple bg-purple;
  }

  .checkbox input:checked ~ .checkmark svg,
  .checkbox input:checked ~ .checkmark .radio {
    @apply block;
  }
}

/* Use the components layer for any more complicated classes you want to add to
 * your project that you’d still like to be able to override with utility
 * classes.
 *
 * https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
 */

@layer components {
  .container {
    @apply mx-auto px-4 sm:px-8;
  }

  .button {
    @apply table min-w-[140px] rounded-full bg-orange px-6 py-3 text-center text-sm font-bold uppercase text-white transition hover:bg-orange-darker;
  }
}

/* Useful when there’s a CSS feature you’d like to use in your project that
 * Tailwind doesn’t include utilities for out of the box.
 *
 * https://tailwindcss.com/docs/adding-custom-styles#adding-custom-utilities
 */

@layer utilities {
  .underline {
    @apply decoration-black/25 decoration-1 underline-offset-4;
  }

  .overlay {
    @apply absolute inset-0 bg-black/25;
  }
}
